/* eslint-disable react/prop-types */
import React from 'react'

const FreeDownload = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    data-name="FreeDownload Button"
    viewBox="0 0 1599.58 390.09"
  >
    <rect
      width="1527.52"
      height="353.12"
      x="34.45"
      y="13.85"
      fill="#af6e3e"
      rx="140.12"
    />
    <path
      fill="#f3ddce"
      d="M371.6 142.59c-2.16 8.16-7 18.24-9.84 26.52-4.32 12.12-9.24 26.76-13.92 41.52 16-9 39.72-20 48.36-20.64h.24c1 0 1.56 1.32 1.56 2.64 0 1.08-.36 2.16-1.44 2.16h-.72c-12 0-40.68 12-50.16 22.56 1.68 2.88 2.4 8.64 2.4 15.6 0 16.92-4 41.16-6.72 50.88-4.44 14.88-10.32 27.72-16.44 27.72h-.24c-2.64 0-3.72-3.48-3.72-8.88 0-21.24 16.08-73 16.8-75.48a12.47 12.47 0 00.36-2.52c0-1.8-1-1.8-2.4-1.8a12.68 12.68 0 01-2.88-.24 4.07 4.07 0 01-1-3 6.39 6.39 0 011.56-4.32 8.37 8.37 0 015.16-1.92 7.82 7.82 0 013.12.72c5.16-16.56 10.68-33.12 15.48-46.56 1.44-4.32 10.44-32 15.24-32h.12a2.84 2.84 0 011.8.73c2.28 2.27 3 7.91 3 13.19a87.64 87.64 0 01-1 12.12 5.84 5.84 0 01-4.08 1.44c-2.4 0-4.8-1.08-5.4-3.12.08-.04 4.64-11.56 4.76-17.32zm-46.44 157a17.09 17.09 0 00.36 4c3.24-3.36 6.6-12 9.6-22.44 3.36-11.4 6.6-39.48 6.6-50.4 0-1.08-.12-1.92-.12-2.64-9 23.08-16.44 58.12-16.44 71.44z"
    />
    <path
      fill="#f3ddce"
      d="M401.24 193.23c4.68-10 11.52-16.32 17.28-16.32 2.52 0 4.8 1.2 6.72 3.72 4.44 9.84 22.2 11.64 35.51 11.64 5.88 0 10.92-.36 13.44-.48a7.61 7.61 0 002.76-1.2 6.91 6.91 0 011.2-.24c.72 0 1 .36 1 .72a2.71 2.71 0 01-1.8 2.28c-.6.6-9.72 2-20.39 2-13.08 0-28.56-2.16-34.56-10.56a3.09 3.09 0 00-2.4-1.44c-8.52 0-24.24 37.56-24.36 37.68v.24c0 1.44-1.68 2-3.48 2-2.16 0-4.56-.84-4.56-2 0-.24.12-.36.12-.48l10.2-42.72a4.68 4.68 0 014.68-3.12c2.28 0 4.56 1.32 4.56 3.48v.24z"
    />
    <path
      fill="#f3ddce"
      d="M497.83 183.75c0 10.08-16.19 16.56-19.56 17.88 0 .72-.12 1.56-.12 2.4 0 2.88.49 6.24 1.92 8.4 1.57 2.4 4.2 3.48 7.57 3.48 16.55 0 49.67-25 49.79-25.08a2.31 2.31 0 011.44-.48 3 3 0 012.88 2.88 2.25 2.25 0 01-1 1.68c-.24.12-32.52 23.4-52.2 23.4-5.15 0-9.48-1.56-12.11-5.64a18.23 18.23 0 01-3.12-10.44c0-6.72 3.36-13.08 7.32-17.76a25.12 25.12 0 016.72-5.64 9.53 9.53 0 014.79-1.44 5.37 5.37 0 012.4.48 5.29 5.29 0 013.12 4.56c.04.48.16.84.16 1.32zm-7.32 0a.31.31 0 00-.36-.36c-2.4.36-4.67 4.8-10.19 12 7.68-6.6 10.55-10.56 10.55-11.64z"
    />
    <path
      fill="#f3ddce"
      d="M556.87 183.75c0 10.08-16.2 16.56-19.56 17.88 0 .72-.12 1.56-.12 2.4 0 2.88.48 6.24 1.92 8.4 1.56 2.4 4.2 3.48 7.56 3.48 16.56 0 49.68-25 49.8-25.08a2.31 2.31 0 011.44-.48 3 3 0 012.88 2.88 2.25 2.25 0 01-1 1.68c-.24.12-32.52 23.4-52.2 23.4-5.16 0-9.48-1.56-12.12-5.64a18.23 18.23 0 01-3.12-10.44c0-6.72 3.36-13.08 7.32-17.76a25.43 25.43 0 016.72-5.64 9.59 9.59 0 014.8-1.44 5.37 5.37 0 012.4.48 5.29 5.29 0 013.12 4.56c.04.48.16.84.16 1.32zm-7.32 0a.32.32 0 00-.36-.36c-2.4.36-4.68 4.8-10.2 12 7.68-6.6 10.56-10.56 10.56-11.64zM654.53 222.39v-85.31h26q15.65 0 25.87 10.43t10.22 26.78v11q0 16.35-10.22 26.75t-25.87 10.4zm7-79.33v73.41h19q12.65 0 20.86-8.84t8.2-22.39v-11.13q0-13.37-8.23-22.21a27.26 27.26 0 00-20.83-8.84zM797.85 186.71q0 16.51-9.05 26.72t-24.29 10.19q-15 0-23.93-10.19t-8.94-26.72v-13.89q0-16.47 8.91-26.72t23.9-10.25q15.24 0 24.32 10.25t9.08 26.72zm-7-14q0-13.81-7-22.26t-19.4-8.45q-12.18 0-19 8.44t-6.8 22.26v14q0 13.93 6.83 22.38t19 8.44q12.48 0 19.43-8.41t6.94-22.41zM830.9 197.9l2.63 13.77h.36l3-13.77 17.82-60.82h7.09l17.87 60.82 2.87 13.83h.35l2.81-13.83 15.06-60.82h7.62l-21.88 85.31h-6.68l-19.27-65.45-2.11-9.2h-.35l-2 9.2-19.51 65.45h-6.68l-21.85-85.31h7.61zM985.12 222.39h-7l-48.46-73-.35.06v72.95h-7v-85.32h7l48.45 72.83.36-.06v-72.77h7zM1013.94 216.47H1058v5.92h-51.1v-85.31h7zM1131.78 186.71q0 16.51-9.06 26.72t-24.28 10.19q-15 0-23.94-10.19t-8.93-26.72v-13.89q0-16.47 8.9-26.72t23.91-10.25q15.22 0 24.31 10.25t9.09 26.72zm-7-14q0-13.81-7-22.26t-19.4-8.45q-12.2 0-19 8.44t-6.79 22.26v14q0 13.93 6.82 22.38t19 8.44q12.48 0 19.42-8.41t6.94-22.41zM1193.36 198.6h-37.44l-8.62 23.79h-7.3l31.35-85.31h6.65l31.11 85.31h-7.26zm-35.22-6.27h32.93l-16.17-45.23h-.35zM1222.3 222.39v-85.31h26q15.65 0 25.87 10.43t10.22 26.78v11q0 16.35-10.22 26.75t-25.87 10.4zm7-79.33v73.41h19q12.66 0 20.86-8.84t8.2-22.39v-11.13q0-13.37-8.23-22.21a27.25 27.25 0 00-20.83-8.84z"
    />
  </svg>
)

export default FreeDownload
