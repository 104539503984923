import React from 'react'
import PropTypes from 'react'

const ProseWrapper = props => {
  console.log(props)
  return (
    <article className="mx-auto px-8 prose prose-sm sm:prose lg:prose-lg xl:prose-xl">
      {props.children}
    </article>
  )
}

ProseWrapper.propTypes = { children: PropTypes.node }

export default ProseWrapper
